@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 400;
  src: local(Montserrat), url("Montserrat-Regular.83e81da8.woff2") format("woff2"), url("Montserrat-Regular.a50d8eb2.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 400;
  src: local(Montserrat), url("Montserrat-Italic.6d837d82.woff2") format("woff2"), url("Montserrat-Italic.9580674e.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 250;
  src: local(Montserrat), url("Montserrat-Thin.ba5f6581.woff2") format("woff2"), url("Montserrat-Thin.71b7f3e4.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 250;
  src: local(Montserrat), url("Montserrat-ThinItalic.79628377.woff2") format("woff2"), url("Montserrat-ThinItalic.36409f23.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 275;
  src: local(Montserrat), url("Montserrat-ExtraLight.6b451554.woff2") format("woff2"), url("Montserrat-ExtraLight.7f5bedad.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 275;
  src: local(Montserrat), url("Montserrat-ExtraLightItalic.af21ae49.woff2") format("woff2"), url("Montserrat-ExtraLightItalic.1e26487c.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 300;
  src: local(Montserrat), url("Montserrat-Light.6715e8bf.woff2") format("woff2"), url("Montserrat-Light.24ca0076.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 300;
  src: local(Montserrat), url("Montserrat-LightItalic.025cf39f.woff2") format("woff2"), url("Montserrat-LightItalic.362618ab.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  src: local(Montserrat), url("Montserrat-Medium.3e1d2f02.woff2") format("woff2"), url("Montserrat-Medium.736c400d.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 500;
  src: local(Montserrat), url("Montserrat-MediumItalic.dd07d808.woff2") format("woff2"), url("Montserrat-MediumItalic.848b2314.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 600;
  src: local(Montserrat), url("Montserrat-SemiBold.81b97deb.woff2") format("woff2"), url("Montserrat-SemiBold.71eb7418.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 600;
  src: local(Montserrat), url("Montserrat-SemiBoldItalic.c7f6ecba.woff2") format("woff2"), url("Montserrat-SemiBoldItalic.d644d306.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 700;
  src: local(Montserrat), url("Montserrat-Bold.447a2a3e.woff2") format("woff2"), url("Montserrat-Bold.f47ec530.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 700;
  src: local(Montserrat), url("Montserrat-BoldItalic.587b9b97.woff2") format("woff2"), url("Montserrat-BoldItalic.49bb74de.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 800;
  src: local(Montserrat), url("Montserrat-ExtraBold.5a58940c.woff2") format("woff2"), url("Montserrat-ExtraBold.32f3ae24.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 800;
  src: local(Montserrat), url("Montserrat-ExtraBoldItalic.6a62ee3e.woff2") format("woff2"), url("Montserrat-ExtraBoldItalic.8e166f55.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 900;
  src: local(Montserrat), url("Montserrat-Black.518e57c7.woff2") format("woff2"), url("Montserrat-Black.a41b16ff.ttf") format("truetype");
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 900;
  src: local(Montserrat), url("Montserrat-BlackItalic.7114ff92.woff2") format("woff2"), url("Montserrat-BlackItalic.5c96f6c0.ttf") format("truetype");
}

/*# sourceMappingURL=index.94242762.css.map */
