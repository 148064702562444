@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat'), url("./Montserrat-Regular.woff2") format('woff2'), url("./Montserrat-Regular.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 400;
    src: local('Montserrat'), url("./Montserrat-Italic.woff2") format('woff2'), url("./Montserrat-Italic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 250;
    src: local('Montserrat'), url("./Montserrat-Thin.woff2") format('woff2'), url("./Montserrat-Thin.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 250;
    src: local('Montserrat'), url("./Montserrat-ThinItalic.woff2") format('woff2'), url("./Montserrat-ThinItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 275;
    src: local('Montserrat'), url("./Montserrat-ExtraLight.woff2") format('woff2'), url("./Montserrat-ExtraLight.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 275;
    src: local('Montserrat'), url("./Montserrat-ExtraLightItalic.woff2") format('woff2'), url("./Montserrat-ExtraLightItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat'), url("./Montserrat-Light.woff2") format('woff2'), url("./Montserrat-Light.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 300;
    src: local('Montserrat'), url("./Montserrat-LightItalic.woff2") format('woff2'), url("./Montserrat-LightItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 500;
    src: local('Montserrat'), url("./Montserrat-Medium.woff2") format('woff2'), url("./Montserrat-Medium.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 500;
    src: local('Montserrat'), url("./Montserrat-MediumItalic.woff2") format('woff2'), url("./Montserrat-MediumItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat'), url("./Montserrat-SemiBold.woff2") format('woff2'), url("./Montserrat-SemiBold.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 600;
    src: local('Montserrat'), url("./Montserrat-SemiBoldItalic.woff2") format('woff2'), url("./Montserrat-SemiBoldItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat'), url("./Montserrat-Bold.woff2") format('woff2'), url("./Montserrat-Bold.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 700;
    src: local('Montserrat'), url("./Montserrat-BoldItalic.woff2") format('woff2'), url("./Montserrat-BoldItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 800;
    src: local('Montserrat'), url("./Montserrat-ExtraBold.woff2") format('woff2'), url("./Montserrat-ExtraBold.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 800;
    src: local('Montserrat'), url("./Montserrat-ExtraBoldItalic.woff2") format('woff2'), url("./Montserrat-ExtraBoldItalic.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat'), url("./Montserrat-Black.woff2") format('woff2'), url("./Montserrat-Black.ttf") format('truetype')
}

@font-face {
    font-family: montserrat;
    font-style: italic;
    font-weight: 900;
    src: local('Montserrat'), url("./Montserrat-BlackItalic.woff2") format('woff2'), url("./Montserrat-BlackItalic.ttf") format('truetype')
}